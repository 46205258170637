import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Fade from 'react-reveal/Fade';
import Tilt from "react-parallax-tilt";
import "./About.css";
import myImg from "../../assets/DP.png"
import Hi from "../../assets/Hi.gif"
import GitHubImg from "../../assets/images/socials/github.png"
import LinkedinImg from "../../assets/images/socials/linkedin.png"
import MediumImg from "../../assets/images/socials/medium.png"
import StackOverImg from "../../assets/images/socials/stackoverflow.png"
import TwitterImg from "../../assets/images/socials/twitter.png"
import InstaImg from "../../assets/images/socials/instagram.png"
import Type from "./Type";

function About() {
    return (
        <Fade bottom duration="2000">
            <Container fluid className="about__section" id="about">
                <Container>
                    <Row>
                        <Col md={8} className="about__description">

                            <div className="text__center">
                                <h1 className="about__intro">Hi <img alt='Hii' src={Hi} width="50px" ></img>, I'm Ashim</h1>
                                <Type />
                            </div>

                            <p className="about__body" >

                                <main id="container">
                                    <div id="terminal">

                                        <section id="terminal__bar">
                                            <div id="bar__buttons">
                                                <button className="bar__button" id="bar__button--exit">&#10005;</button>
                                                <button className="bar__button">&#9472;</button>
                                                <button className="bar__button">&#9723;</button>
                                            </div>
                                            <p id="bar__user">ashim@ubuntu: ~</p>
                                        </section>

                                        <section id="terminal__body">
                                            <div id="terminal__prompt">
                                                <span id="terminal__prompt--user">ashim@ubuntu:</span>
                                                <span id="terminal__prompt--location">~</span>
                                                <span id="terminal__prompt--bling">$ cat about_ashim.txt</span>
                                            </div>
                                            <div id="terminal__bio">Hey, I'm a Front-End Developer and Learning SysAdmin, I use mainly JavaScript, NodeJS and Bootstrap<br />
                                                <br />
                                                I am a <i>
                                                    <b> Web Developer</b>

                                                </i>
                                            </div>
                                            <div id="terminal__prompt" >
                                                <span id="terminal__prompt--user">ashim@ubuntu:</span>
                                                <span id="terminal__prompt--location">~</span>
                                                <span id="terminal__prompt--bling">$</span>
                                                <span id="terminal__prompt--cursor"></span>
                                            </div>
                                        </section>

                                    </div>
                                </main>


                            </p>
                            <br />

                            <div className="text_center_link">

                                <a rel="Github" href="https://github.com/ashimahamed" target="blank">
                                    <img src={GitHubImg} alt="Github" />
                                </a>

                                <a rel="Instagram" href="https://www.instagram.com/ashim_ahamed" alt="ashim_ahamed" target="blank"> <img src={InstaImg} alt="Instagram" />
                                </a>

                            </div>

                        </Col>
                        <Col md={4} className="myAvtar">
                            <Tilt>
                                <img src={myImg} className="profile_image" alt="Me" />
                            </Tilt>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </Fade>
    );
}
export default About;

import React, { Component } from 'react'
import "./Projects.css"
import Fade from 'react-reveal/Fade';
import GitHub from "../../assets/images/project-page/github.png"
import Web from "../../assets/images/project-page/website.png"
import DISCORD from "../../assets/images/project-page/DISCORD.png"
import Signal from "../../assets/images/project-page/Signal.png"
import AshBot from "../../assets/images/project-page/AshBot.png"

export class Projects extends Component {
    render() {
        return (
            <>
                <div id="projects">

                    <Fade left>
                        <div>
                            <h2 className="projectbg">Projects</h2>
                            <div className="projectfr">Projects</div>
                        </div>
                    </Fade>

                    <Fade bottom cascade>
                        <section id="main">
                            <div class="container_proj">

                                <div className="card">
                                    <div className="imgBx">
                                        <img src={DISCORD} alt="" />
                                    </div>
                                    <div className="content">
                                        <div className="contentBx" >
                                            <h3 className="text-black">SA-MP Status <br /><span
                                            >(Python)<br />A Discord Bot to Monitor SA-MP Server.
                                            </span></h3>
                                        </div>
                                        <ul className="sci">
                                            <li>
                                                <a rel="noreferrer" href="https://github.com/ashimahamed/sa-mp-status" target="_blank"><img alt="github" width="45px" height="45px" src={GitHub} /></a>
                                            </li>
                                            <li className="web">
                                                <a rel="noreferrer" href="/#" target="_blank"><img alt="web"
                                                    width="45px" height="45px" src={Web} /></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="card">
                                    <div className="imgBx">
                                        <img src={AshBot} alt="" />
                                    </div>
                                    <div className="content">
                                        <div className="contentBx" >
                                            <h3 className="text-white">Ash Bot <br /><span
                                            >(JavaScript, MongoDB)<br />A Moderation and Music Discord Bot.
                                            </span></h3>
                                        </div>
                                        <ul className="sci">
                                            <li>
                                                <a rel="noreferrer" href="/#" target="_blank"><img alt="github" width="45px" height="45px" src={GitHub} /></a>
                                            </li>
                                            <li className="web">
                                                <a rel="noreferrer" href="/#" target="_blank"><img alt="web"
                                                    width="45px" height="45px" src={Web} /></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>


                            </div>
                        </section>
                    </Fade>
                </div>

                <Fade bottom>
                    <div className="btn_center">
                        <a href="https://github.com/ashimahamed" target="blank"><div className="btn" style={{ color: "white" }}>More Projects
                            <div className="btn2"></div>
                        </div></a>
                    </div>
                </Fade>

            </>

        )
    }
}

export default Projects

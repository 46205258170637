import React, { Component } from 'react'
import "./WorkExperience.css";
import Fade from 'react-reveal/Fade';
import Coding from "../../assets/images/experience-page/coding.png"
import IURP from "../../assets/images/experience-page/IURP.png"
import AshHost from "../../assets/images/experience-page/ashhost.png"

export class WorkExperience extends Component {
    render() {
        return (
            <div id="experiences">

                <Fade left>
                    <div>
                        <div className="exp-Bg">Work Experience</div>
                        <h2 className="exp-Fr">Work Experience</h2>
                    </div>
                </Fade>

                <Fade bottom cascade>
                    <div className="work-exp">
                        <ul className="work-exp-list">

                            <li>
                                <div className="exp">
                                    <img src={AshHost} className="exp__image" alt="" />
                                    <div className="exp__overlay">
                                        <div className="exp__header">
                                            <svg className="exp__arc" xmlns="http://www.w3.org/2000/svg">
                                                <path />
                                            </svg>
                                            <img alt="devloper" className="exp__thumb" width="60px" src={Coding} />
                                            <div className="exp__header-text">
                                                <h3 className="exp__title">Ash Host</h3>
                                                <span className="exp__status">Director<br />
                                                    Feb' 21 - Till Now</span>
                                            </div>
                                        </div>
                                        <p className="exp__description">Learned valuable skills and knowledge in SysAdmin and Web Development</p>
                                    </div>
                                </div>
                            </li>

                            <li>
                                <div className="exp">
                                    <img src={IURP} className="exp__image" alt="" />
                                    <div className="exp__overlay">
                                        <div className="exp__header">
                                            <svg className="exp__arc" xmlns="http://www.w3.org/2000/svg">
                                                <path />
                                            </svg>
                                            <img alt="devloper" className="exp__thumb" width="60px" src={Coding} />
                                            <div className="exp__header-text">
                                                <h3 className="exp__title">Irandaam Ulagam RP</h3>
                                                <span className="exp__status">Director / Developer<br />
                                                    Dec' 22 - Till Now</span>
                                            </div>
                                        </div>
                                        <p className="exp__description">Learned a lot about C++.</p>
                                    </div>
                                </div>
                            </li>
                            
                        </ul>
                    </div>
                </Fade>
            </div>
        )
    }
}

export default WorkExperience

import React, { Component } from 'react'
import Fade from 'react-reveal/Fade';
import LoveImg from "../../assets/images/footer/love.png"
import ReactImg from "../../assets/images/footer/react.png"
import "./Footer.css"
import AshHost from "../../assets/images/experience-page/ashhost.png"

export class Footer extends Component {
    render() {
        let url="https://ashhost.in";
        return (
            
            <Fade bottom delay="100">
                <div className="footer_text">&lt;/&gt; with <img alt="love" className="footer_icon" width="35px" height="35px" src={LoveImg} /> and &nbsp;<img alt="React Js" width="25px" height="25px" src={ReactImg} /> by Ashim</div>
                <div className="footer_ash"> <img alt="ashhost" className="footer_icon" width="35px" height="35px"src={AshHost} /> Hosting Partner <a href={url}>Ash Host</a> </div>

            </Fade>
            
        )
    }
}

export default Footer
